<div class="header" id="secondToolbar" [ngClass]="{'sticky' : isSticky}">
  <div class="header-menu">
    <div class="main-logo">
      <a [routerLink]="['/home']">
        <img src="./assets/images/logo/logo.svg">
      </a>
    </div>

    <ul class="main-menu"  [ngClass]="{'open-menu' : isOpen}" *ngIf="menuItems && menuItems.length">
      <li *ngFor="let item of menuItems">
        <a routerLinkActive="active" [routerLink]="[item.url]"> {{item.name}} </a>
        <div class="icon" (click)="item.openDrop = !item.openDrop" *ngIf="item.subMenu && item.subMenu.length > 0">
          <svg xmlns="http://www.w3.org/2000/svg" width="10.103" height="6.174" viewBox="0 0 10.103 6.174" >
            <path id="angle-left" d="M9.163,11.08l4.073-4.044a.965.965,0,0,1,1.364,0,.949.949,0,0,1,0,1.345l-3.4,3.376,3.4,3.376a.949.949,0,0,1,0,1.345.979.979,0,0,1-1.364,0L9.163,12.434a.949.949,0,0,1,0-1.354Z" transform="matrix(0.017, -1, 1, 0.017, -6.908, 14.759)" fill="#7a8393"/>
          </svg>
        </div>
        <ul  class="dropDown" [ngClass]="{'isOpen' : item.openDrop}" *ngIf="item.subMenu && item.subMenu.length > 0">
          <!-- [queryParams]="{ Id: subiItem.id}" -->
          <li *ngFor="let subiItem of item.subMenu">
            <a [routerLink]="[subiItem.url]">
              <img [src]="subiItem.svgCode">
              <span> {{subiItem.name}}</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a [routerLink]="['/telemedicine']" routerLinkActive="active">{{'booking.Book a Telemedicine' | translate}}</a>
      </li>
      <li class="btn-menu login-btn">
        <a href="{{env.systemUrl}}/login" target="_blank"> {{ 'general.LogIn' | translate }} </a>
      </li>
      <li class="btn-menu start-btn">
        <a href="{{env.systemUrl}}/register?packageCode={{packageId}}"  target="_blank"> {{ 'general.StartFree' | translate }}</a>
      </li>
    </ul>
  </div>
  <a class="open-toggle" (click)="isOpen = !isOpen">
      <svg height="19" viewBox="0 0 20.001 19" width="20.001" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M2157.5,3082a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,1,1,0-3h17a1.5,1.5,0,1,1,0,3Zm0-8a1.5,1.5,0,0,1,0-3h17a1.5,1.5,0,0,1,0,3Z"
              fill="#446475" id="menu-btn" transform="translate(-2156 -3063)">
          </path>
      </svg>
  </a>
  <ul  class="btns-group header-btns d-flex align-items-center justify-content-center">
    <li class="transparent-btn">
      <a href="{{env.systemUrl}}/login" target="_blank"> {{ 'general.LogIn' | translate }} </a>
    </li>
    <li class="primary-btn">
      <a href="{{env.systemUrl}}/register?packageCode={{packageId}}"  target="_blank"> {{ 'general.StartFree' | translate }}</a>
    </li>
    <li class="lang-btn">
      <a (click)="changeLanguage()">
        <img  class="ar-logo" src="./assets/images/icons/Language-ar.png" [hidden]="language === 'ar'">
        <img  class="ar-logo" src="./assets/images/uk.png" [hidden]="language === 'en'">
      </a>
    </li>
  </ul>
</div>

